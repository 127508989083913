import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';
import {DateFormat} from "../../../settings/DateFormat/DateFormat";

export const AlumnosService = {
	Listar: (filtro, paginacion) => {
		let params = {
			filtro: {
				nombre: filtro.nombre || null,
				correo_electronico: filtro.correo_electronico || null,
				telefono: filtro.telefono || null,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : 'all',
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Alumnos_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let fecha_ingreso = (form.fecha_ingreso || null) ? DateFormat.FormatSql(form.fecha_ingreso) : null;
		let fecha_nacimiento = (form.fecha_nacimiento || null) ? DateFormat.FormatSql(form.fecha_nacimiento) : null;
		let fecha_inicio_pago = (form.fecha_inicio_pago || null) ? DateFormat.FormatSql(form.fecha_inicio_pago) : null;
		let fecha_fin_pago = (form.fecha_fin_pago || null) ? DateFormat.FormatSql(form.fecha_fin_pago) : null;
		
		let params = {
			id_alumno: form.id_alumno || null,
			nombre: form.nombre || '',
			apellido_paterno: form.apellido_paterno || '',
			apellido_materno: form.apellido_materno || '',
			telefono: form.telefono || '',
			numero_emergencia: form.numero_emergencia || '',
			correo_electronico: form.correo_electronico || '',
			tipo_sangre: form.tipo_sangre || '',
			fecha_nacimiento: fecha_nacimiento,
			lugar_nacimiento: form.lugar_nacimiento || '',
			codigo_postal: form.codigo_postal || '',
			direccion: form.direccion || '',
			alergia_lesion_otro: form.alergia_lesion_otro || '',
			tutor: form.tutor || '',
			fecha_ingreso: fecha_ingreso,
			id_cat_modalidad: form.id_cat_modalidad || '',
			id_cat_nivel: form.id_cat_nivel || '',
			id_cat_horario: form.id_cat_horario || '',
			id_cat_tipo_inscripcion: form.id_cat_tipo_inscripcion || '',
			costo: form.costo || '',
			fecha_inicio_pago: fecha_inicio_pago,
			fecha_fin_pago: fecha_fin_pago,
			id_usuario: form.id_usuario || '',
			certificado_medico: Number(form.certificado_medico),
			responsiva: Number(form.responsiva),
			edad: form.edad || '',
			notas: form.notas || '',
			foto: form.foto || '',
			formato: form.formato || '',
			activo: Number(form.activo)
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Alumnos_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let fecha_ingreso = (form.fecha_ingreso || null) ? DateFormat.FormatSql(form.fecha_ingreso) : null;
		let fecha_nacimiento = (form.fecha_nacimiento || null) ? DateFormat.FormatSql(form.fecha_nacimiento) : null;
		let fecha_inicio_pago = (form.fecha_inicio_pago || null) ? DateFormat.FormatSql(form.fecha_inicio_pago) : null;
		let fecha_fin_pago = (form.fecha_fin_pago || null) ? DateFormat.FormatSql(form.fecha_fin_pago) : null;
		
		let params = {
			id_alumno: form.id_alumno || null,
			nombre: form.nombre || '',
			apellido_paterno: form.apellido_paterno || '',
			apellido_materno: form.apellido_materno || '',
			telefono: form.telefono || '',
			numero_emergencia: form.numero_emergencia || '',
			correo_electronico: form.correo_electronico || '',
			tipo_sangre: form.tipo_sangre || '',
			fecha_nacimiento: fecha_nacimiento,
			lugar_nacimiento: form.lugar_nacimiento || '',
			codigo_postal: form.codigo_postal || '',
			direccion: form.direccion || '',
			alergia_lesion_otro: form.alergia_lesion_otro || '',
			tutor: form.tutor || '',
			fecha_ingreso: fecha_ingreso,
			id_cat_modalidad: form.id_cat_modalidad || '',
			id_cat_nivel: form.id_cat_nivel || '',
			id_cat_horario: form.id_cat_horario || '',
			id_cat_tipo_inscripcion: form.id_cat_tipo_inscripcion || '',
			costo: form.costo || '',
			fecha_inicio_pago: fecha_inicio_pago,
			fecha_fin_pago: fecha_fin_pago,
			id_usuario: form.id_usuario || '',
			certificado_medico: Number(form.certificado_medico),
			responsiva: Number(form.responsiva),
			edad: form.edad || '',
			notas: form.notas || '',
			foto: form.foto || '',
			formato: form.formato || '',
			activo: Number(form.activo)
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Alumnos_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_acreditado) => {let params = {
			id_acreditado: id_acreditado
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Alumnos_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
