import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button/index';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Zoom from '@material-ui/core/Zoom';
import Add from '@material-ui/icons/Add';
import Fab from "@material-ui/core/Fab";
import {AlumnosService} from '../../../../services/_Sis/AlumnosService/AlumnosService';
import IconButton from "@material-ui/core/IconButton";
import {SearchOutlined, EditOutlined, DateRangeOutlined, PhotoCameraOutlined, SaveOutlined, CancelOutlined} from '@material-ui/icons';
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormGroup from "@material-ui/core/FormGroup"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../../settings/IntegrationNotistack/IntegrationNotistack";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {KeyboardDatePicker} from '@material-ui/pickers';
import moment from 'moment';
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import ModalCambiarFotoDePerfil from "../../Perfil/Includes/ModalCambiarFotoDePerfil";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from '@material-ui/core/CardActions';
import Typography from "@material-ui/core/Typography";
import {CONFIG} from "../../../../settings/Config/Config";
import {FileBase64} from "../../../../settings/FileBase64/FileBase64";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import InputAdornment from "@material-ui/core/InputAdornment";
import MDDatePicker from "../../../Include/MDDatePicker/MDDatePicker";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import {HttpRequest} from "../../../../settings/HttpRequest/HttpRequest";

class ModalAlumnos extends Component {
	
	state = {};
	
	Usr = ReactLocalStorageService.get('Usr') || {};
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			open: false,
			scroll: 'paper',
			id_alumno: props.item.id_alumno || 0,
			tipo: props.tipo,
			
			nombre: '',
			apellido_paterno: '',
			apellido_materno: '',
			telefono: '',
			numero_emergencia: '',
			correo_electronico: '',
			tipo_sangre: '',
			fecha_nacimiento: null,
			lugar_nacimiento: '',
			codigo_postal: '',
			direccion: '',
			alergia_lesion_otro: '',
			tutor: '',
			fecha_ingreso: null,
			id_cat_modalidad: '',
			id_cat_nivel: '',
			id_cat_horario: '',
			id_cat_tipo_inscripcion: '',
			costo: '',
			fecha_inicio_pago: null,
			fecha_fin_pago: null,
			id_usuario: '',
			certificado_medico: '',
			responsiva: '',
			edad: '',
			notas: '',
			activo: '',
			
			listar_cat_modalidad: [],
			listar_cat_horario: [],
			listar_cat_nivel: [],
			listar_cat_instructor: [],
			listar_cat_tipo_inscripcion: [],
			
			base64Tipo: '',
			base64: '',
			foto: '',
			formato: '',
		};
		
		this.handleChange = this.handleChange.bind(this);
		
		this.save = this.save.bind(this);
		this.add = this.add.bind(this);
		this.edit = this.edit.bind(this);
		this.view = this.view.bind(this);
		
		this.BOTON_ACCION = this.BOTON_ACCION.bind(this);
		
	}
	
	showSnackBars(type, message) {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	openModal = () => {
		this.setState({open: true, scroll: 'paper'});
	};
	
	modalClose = () => {
		this.setState({open: false});
	};
	
	listarInstructor = () => {
		return new Promise((resolve, reject) => {
			CatService.ListInstructor().then((response) => {
				this.setState({listar_cat_instructor: response.data});
				resolve(response);
			}).catch((error) => {
				this.setState({listar_cat_instructor: []});
				reject(error);
			});
		});
	};
	
	listarModalidad = () => {
		return new Promise((resolve, reject) => {
			CatService.ListModalidad().then((response) => {
				this.setState({listar_cat_modalidad: response.data});
				resolve(response);
			}).catch((error) => {
				this.setState({listar_cat_modalidad: []});
				reject(error);
			});
		});
	};
	
	listarTipoInscripcion = () => {
		return new Promise((resolve, reject) => {
			CatService.ListTipoInscripcion().then((response) => {
				this.setState({listar_cat_tipo_inscripcion: response.data});
				resolve(response);
			}).catch((error) => {
				this.setState({listar_cat_tipo_inscripcion: []});
				reject(error);
			});
		});
	};

	listarHorario = () => {
		return new Promise((resolve, reject) => {
			CatService.ListHorario().then((response) => {
				this.setState({listar_cat_horario: response.data});
				resolve(response);
			}).catch((error) => {
				this.setState({listar_cat_horario: []});
				reject(error);
			});
		});
	};
	
	listarNivel = () => {
		return new Promise((resolve, reject) => {
			CatService.ListNivel().then((response) => {
				this.setState({listar_cat_nivel: response.data});
				resolve(response);
			}).catch((error) => {
				this.setState({listar_cat_nivel: []});
				reject(error);
			});
		});
	};
	
	save = () => {
		if (this.state.id_alumno > 0) {
			AlumnosService.Modificar(this.state).then((response) => {
				this.modalClose();
				this.props.RefreshList();
				this.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
			});
		} else {
			AlumnosService.Agregar(this.state).then((response) => {
				this.modalClose();
				this.props.RefreshList();
				this.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
			});
		}
	};
	
	add = () => {
		
		console.log(this.props);
		this.listarInstructor();
		this.listarModalidad();
		this.listarTipoInscripcion();
		this.listarHorario();
		this.listarNivel();
		
		this.setState({
			id_alumno: '',
			nombre: '',
			apellido_paterno: '',
			apellido_materno: '',
			telefono: '',
			numero_emergencia: '',
			correo_electronico: '',
			tipo_sangre: '',
			fecha_nacimiento: null,
			lugar_nacimiento: '',
			codigo_postal: '',
			direccion: '',
			alergia_lesion_otro: '',
			tutor: '',
			fecha_ingreso: null,
			id_cat_modalidad: '',
			id_cat_nivel: '',
			id_cat_horario: '',
			id_cat_tipo_inscripcion: '',
			costo: '',
			fecha_inicio_pago: null,
			fecha_fin_pago: null,
			id_usuario: '',
			certificado_medico: '',
			responsiva: '',
			edad: '',
			notas: '',
			foto: '',
			activo: 1,
		});
		
		this.openModal();
		
	};
	
	edit = () => {
		
		const {item} = this.props;
		
		this.listarInstructor().then(() => {
			this.setState({ id_usuario: item.id_usuario || ''});
		});
		this.listarModalidad().then(() => {
			this.setState({ id_cat_modalidad: item.id_cat_modalidad || ''});
		});
		this.listarTipoInscripcion().then(() => {
			this.setState({ id_cat_nivel: item.id_cat_nivel || ''});
		});
		this.listarHorario().then(() => {
			this.setState({ id_cat_horario: item.id_cat_horario || ''});
		});
		this.listarNivel().then(() => {
			this.setState({ id_cat_tipo_inscripcion: item.id_cat_tipo_inscripcion || ''});
		});
		
		this.setState({
			id_alumno: item.id_alumno || '',
			nombre: item.nombre || '',
			apellido_paterno: item.apellido_paterno || '',
			apellido_materno: item.apellido_materno || '',
			telefono: item.telefono || '',
			numero_emergencia: item.numero_emergencia || '',
			correo_electronico: item.correo_electronico || '',
			tipo_sangre: item.tipo_sangre || '',
			fecha_nacimiento: item.fecha_nacimiento ? moment(item.fecha_nacimiento) : null,
			lugar_nacimiento: item.lugar_nacimiento || '',
			codigo_postal: item.codigo_postal || '',
			direccion: item.direccion || '',
			alergia_lesion_otro: item.alergia_lesion_otro || '',
			tutor: item.tutor || '',
			fecha_ingreso: item.fecha_ingreso ? moment(item.fecha_ingreso) : null,
			costo: item.costo || '',
			fecha_inicio_pago: item.fecha_inicio_pago ? moment(item.fecha_inicio_pago) : null,
			fecha_fin_pago: item.fecha_fin_pago ? moment(item.fecha_fin_pago) : null,
			certificado_medico: (Number(item.certificado_medico) === 1 ? '1':'0'),
			responsiva: (Number(item.responsiva) === 1 ? '1':'0'),
			edad: item.edad || '',
			notas: item.notas || '',
			foto: item.foto || '',
			activo: (Number(item.activo) === 1),
		});
		
		this.openModal('paper');
	};
	
	view = () => {
		
		const {item} = this.props;
		
		this.listarInstructor().then(() => {
			this.setState({ id_usuario: item.id_usuario || ''});
		});
		this.listarModalidad().then(() => {
			this.setState({ id_cat_modalidad: item.id_cat_modalidad || ''});
		});
		this.listarTipoInscripcion().then(() => {
			this.setState({ id_cat_nivel: item.id_cat_nivel || ''});
		});
		this.listarHorario().then(() => {
			this.setState({ id_cat_horario: item.id_cat_horario || ''});
		});
		this.listarNivel().then(() => {
			this.setState({ id_cat_tipo_inscripcion: item.id_cat_tipo_inscripcion || ''});
		});
		
		this.setState({
			id_alumno: item.id_alumno || '',
			nombre: item.nombre || '',
			apellido_paterno: item.apellido_paterno || '',
			apellido_materno: item.apellido_materno || '',
			telefono: item.telefono || '',
			numero_emergencia: item.numero_emergencia || '',
			correo_electronico: item.correo_electronico || '',
			tipo_sangre: item.tipo_sangre || '',
			fecha_nacimiento: item.fecha_nacimiento ? moment(item.fecha_nacimiento) : null,
			lugar_nacimiento: item.lugar_nacimiento || '',
			codigo_postal: item.codigo_postal || '',
			direccion: item.direccion || '',
			alergia_lesion_otro: item.alergia_lesion_otro || '',
			tutor: item.tutor || '',
			fecha_ingreso: item.fecha_ingreso ? moment(item.fecha_ingreso) : null,
			costo: item.costo || '',
			fecha_inicio_pago: item.fecha_inicio_pago ? moment(item.fecha_inicio_pago) : null,
			fecha_fin_pago: item.fecha_fin_pago ? moment(item.fecha_fin_pago) : null,
			certificado_medico: (Number(item.certificado_medico) === 1 ? '1':'0'),
			responsiva: (Number(item.responsiva) === 1 ? '1':'0'),
			edad: item.edad || '',
			notas: item.notas || '',
			foto: item.foto || '',
			activo: (Number(item.activo) === 1),
		});
		
		this.openModal('paper');
	};
	
	BOTON_ACCION = () => {
		var BTN_ACTION = '';
		
		if (this.state.id_alumno > 0) {
			if (this.state.tipo === 'edit') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Editar">
						<IconButton aria-label="Editar" onClick={() => this.edit()}>
							<EditOutlined/>
						</IconButton>
					</Tooltip>
				);
			} else if (this.state.tipo === 'view') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Detalles">
						<IconButton aria-label="Detalles" onClick={() => this.view()}>
							<SearchOutlined/>
						</IconButton>
					</Tooltip>
				);
			}
		} else {
			BTN_ACTION = (
				<Zoom className={'btn-fixed-bottom-right cursor-pointer'} key={'inherit'} timeout={1500} in={true}
				      style={{transitionDelay: `${100}ms`}} unmountOnExit>
					<Fab color={'primary'} onClick={() => this.add()}>
						<Add/>
					</Fab>
				</Zoom>
			);
		}
		return BTN_ACTION;
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	is_admin = () => {
		return FieldsJs.inArray([2], this.Usr.id_cat_tipo_usuario);
	};
	
	fileSelect = () => {
		document.getElementById('cambiar_foto_alumno').click();
	};
	
	limpiar_foto_de_portada = () => {
		this.setState({
			base64Tipo: '',
			base64: '',
			foto: '',
			formato: '',
		});
		document.getElementById("cambiar_foto_alumno").value = '';
	};
	
	getBase64 = (e) => {
		let formatos = [
			"image/jpeg",
			"image/png"
		];
		FileBase64.Base64(e.target, formatos).then((response) => {
			this.setState({
				base64Tipo: response.base64Tipo,
				base64: response.base64,
				foto: response.archivo,
				formato: response.formato,
			});
		}).catch((error) => {
			this.showSnackBars('error', error.mensaje);
			this.setState({
				base64Tipo: '',
				base64: '',
				foto: '',
				formato: '',
			});
		});
	};
	
	render() {
		
		const classes = {};
		
		const BTN_ACTION = this.BOTON_ACCION();
		
		const foto = this.state.base64 ? this.state.base64 : this.state.foto ? CONFIG.src + this.state.foto : CONFIG.foto_fondo_default;
		
		const bg_foto = {
			backgroundColor: `black`,
			backgroundImage: `url(${foto})`
		};
		
		return (
			<div>
				
				{BTN_ACTION}
				
				<Dialog disableBackdropClick disableEscapeKeyDown maxWidth={'md'} open={this.state.open}
				        fullWidth={true} scroll={this.state.scroll} onClose={this.modalClose}
				        aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogTitle>
						{this.state.tipo === 'edit' ? 'Actualizar' : (this.state.tipo === 'view' ? 'Detalle' : 'Agregar')} Alumno
					</DialogTitle>
					
					<DialogContent className={'padding-20 padding-0-T'}>
						
						<Grid container spacing={2}>
							
							<Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
								<Grid container spacing={2}>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<TextField
											className={'margin-0'}
											fullWidth
											onChange={this.handleChange}
											disabled={this.state.tipo === 'view'}
											type="text"
											margin="dense"
											required
											name="nombre"
											label="Nombre(s)"
											value={this.state.nombre}
										/>
									</Grid>
									
									<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
										<TextField
											className={'margin-0'}
											fullWidth
											onChange={this.handleChange}
											disabled={this.state.tipo === 'view'}
											type="text"
											margin="dense"
											required
											name="apellido_paterno"
											label="Apellido Paterno"
											value={this.state.apellido_paterno}
										/>
									</Grid>
									
									<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
										<TextField
											className={'margin-0'}
											fullWidth
											onChange={this.handleChange}
											disabled={this.state.tipo === 'view'}
											type="text"
											margin="dense"
											name="apellido_materno"
											label="Apellido Materno"
											value={this.state.apellido_materno}
										/>
									</Grid>
									
									<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
										<TextField
											className={'margin-0'}
											type={'text'}
											margin="dense"
											fullWidth
											required
											name="telefono"
											onChange={this.handleChange}
											label="Teléfono" autoComplete={'off'}
											value={this.state.telefono}
											inputProps={{maxLength: 10}}
											disabled={this.state.tipo === 'view'}
											onKeyPress={EnteroSolo}
										/>
									</Grid>
									
									<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
										<TextField
											className={'margin-0'}
											type={'text'}
											margin="dense"
											fullWidth
											name="numero_emergencia"
											onChange={this.handleChange}
											label="Número de Emergencia" autoComplete={'off'}
											value={this.state.numero_emergencia}
											inputProps={{maxLength: 10}}
											required
											disabled={this.state.tipo === 'view'}
											onKeyPress={EnteroSolo}
										/>
									</Grid>
									
									<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
										<TextField
											className={'margin-0'}
											fullWidth
											onChange={this.handleChange}
											disabled={this.state.tipo === 'view'}
											type="text"
											margin="dense"
											name="correo_electronico"
											label="Correo electrónico"
											value={this.state.correo_electronico}
										/>
									</Grid>
									
									<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
										<TextField
											className={'margin-0'}
											fullWidth
											onChange={this.handleChange}
											disabled={this.state.tipo === 'view'}
											type="text"
											margin="dense"
											name="tipo_sangre"
											label="Tipo Sangre"
											value={this.state.tipo_sangre}
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
								<Card style={bg_foto} className={'bg-img-cover-x-center-y-center'}>
									<CardContent className={'h-100-84-px'} style={{padding: '15px', border: 'gray 2px dashed'}}>
										<div style={{height: 200, width: '100%'}} align={'right'}>
											<input type="file" id="cambiar_foto_alumno"
											       onChange={(e) => this.getBase64(e)} className={'display-none'}/>
											<Grid
												container
												direction="row"
												justify="flex-end"
												alignItems="center"
											>
												{!this.state.base64Tipo ? (
													<Grid item>
														<IconButton aria-label="Cambiar portada"
														            onClick={() => this.fileSelect()}>
															<PhotoCameraOutlined style={{color: 'black'}}/>
														</IconButton>
													</Grid>
												) : null}
												{!!this.state.base64Tipo ? (
													<Fragment>
														<Grid item>
															<IconButton aria-label="Cancelar portada"
															            onClick={() => this.limpiar_foto_de_portada()}>
																<CancelOutlined style={{color: 'black'}}/>
															</IconButton>
														</Grid>
														<Grid item>
															<IconButton aria-label="Actualizar portada"
															            onClick={() => this.fileSelect()}>
																<SaveOutlined style={{color: 'black'}}/>
															</IconButton>
														</Grid>
													</Fragment>
												) : null}
											</Grid>
										</div>
									</CardContent>
								</Card>
							</Grid>
							
							<Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
								<KeyboardDatePicker
									format={'dd/MM/yyyy'}
									fullWidth
									margin="dense"
									className={'margin-0'}
									clearable
									required
									inputProps={{readOnly: true}}
									KeyboardButtonProps={{
										'aria-label': 'change date',
									}}
									label="Fecha nacimiento"
									value={this.state.fecha_nacimiento}
									onChange={(date) => {
										this.handleChange(null, null, null, date, 'fecha_nacimiento');
									}}
									clearLabel={'Limpiar'}
									okLabel={'Aceptar'}
									cancelLabel={'Cancelar'}
									disabled={this.state.tipo === 'view'}
								
								/>
							</Grid>
							
							<Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
								<TextField
									className={'margin-0'}
									fullWidth
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									margin="dense"
									name="lugar_nacimiento"
									label="Lugar de Nacimiento"
									value={this.state.lugar_nacimiento}
								/>
							</Grid>
							
							<Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
								<TextField
									className={'margin-0'}
									fullWidth
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									margin="dense"
									name="codigo_postal"
									label="Código Postal"
									value={this.state.codigo_postal}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<TextField
									className={'margin-0'}
									fullWidth
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									margin="dense"
									name="direccion"
									label="Dirección"
									value={this.state.direccion}
								/>
							</Grid>
							
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<TextField
									className={'margin-0'}
									fullWidth
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									margin="dense"
									name="alergia_lesion_otro"
									label="Alergia / Lesión / Otro"
									value={this.state.alergia_lesion_otro}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<TextField
									className={'margin-0'}
									fullWidth
									required
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									margin="dense"
									name="tutor"
									label="Nombre del Tutor"
									value={this.state.tutor}
								/>
							</Grid>
							
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
								<KeyboardDatePicker
									format={'dd/MM/yyyy'}
									fullWidth
									clearable
									className={'margin-0'}
									margin="dense"
									required
									inputProps={{readOnly: true}}
									KeyboardButtonProps={{
										'aria-label': 'change date',
									}}
									label="Fecha Ingreso"
									value={this.state.fecha_ingreso}
									onChange={(date) => {
										this.handleChange(null, null, null, date, 'fecha_ingreso');
									}}
									clearLabel={'Limpiar'}
									okLabel={'Aceptar'}
									cancelLabel={'Cancelar'}
									disabled={this.state.tipo === 'view'}
								/>
							</Grid>
							
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
								<TextField
									className={'margin-0'}
									select
									fullWidth
									required
									margin="dense"
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									SelectProps={{
										native: true,
										MenuProps: {
											className: classes.menu,
										},
									}}
									name="id_cat_modalidad"
									label="Modalidad de Clase"
									value={this.state.id_cat_modalidad}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.listar_cat_modalidad.map(option => (
										<option key={option.id_cat_modalidad} value={option.id_cat_modalidad}>
											{option.modalidad}
										</option>
									))}
								</TextField>
							</Grid>
							
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
								<TextField
									className={'margin-0'}
									select
									fullWidth
									required
									margin="dense"
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									SelectProps={{
										native: true,
										MenuProps: {
											className: classes.menu,
										},
									}}
									name="id_cat_nivel"
									label="Nivel"
									value={this.state.id_cat_nivel}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.listar_cat_nivel.map(option => (
										<option key={option.id_cat_nivel} value={option.id_cat_nivel}>
											{option.nivel}
										</option>
									))}
								</TextField>
							</Grid>
							
							
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
								<TextField
									className={'margin-0'}
									select
									fullWidth
									required
									margin="dense"
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									SelectProps={{
										native: true,
										MenuProps: {
											className: classes.menu,
										},
									}}
									name="id_cat_horario"
									label="Horario"
									value={this.state.id_cat_horario}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.listar_cat_horario.map(option => (
										<option key={option.id_cat_horario} value={option.id_cat_horario}>
											{option.horario}
										</option>
									))}
								</TextField>
							</Grid>
							
							
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
								<TextField
									className={'margin-0'}
									select
									fullWidth
									margin="dense"
									required
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									SelectProps={{
										native: true,
										MenuProps: {
											className: classes.menu,
										},
									}}
									name="id_cat_tipo_inscripcion"
									label="Cuota"
									value={this.state.id_cat_tipo_inscripcion}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.listar_cat_tipo_inscripcion.map(option => (
										<option key={option.id_cat_tipo_inscripcion} value={option.id_cat_tipo_inscripcion}>
											{option.tipo_inscripcion}
										</option>
									))}
								</TextField>
							</Grid>
							
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
								<TextField
									className={'margin-0'}
									type={'text'}
									margin="dense"
									fullWidth
									name="costo"
									required
									onChange={this.handleChange}
									label="Costo" autoComplete={'off'}
									value={this.state.costo}
									inputProps={{maxLength: 20}}
									disabled={this.state.tipo === 'view'}
									onKeyPress={EnteroSolo}
								/>
							</Grid>
							
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
								<MDDatePicker
									type={'range'}
									component={(
										<TextField
											fullWidth
											label="Fecha Pago"
											className={'margin-0'}
											margin="dense"
											value={(this.state.fecha_inicio_pago && this.state.fecha_fin_pago) ? (DateFormat.FormatText(this.state.fecha_inicio_pago) + " al " + DateFormat.FormatText(this.state.fecha_fin_pago)) : ''}
											readOnly
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<IconButton aria-label="Fecha Pago">
															<DateRangeOutlined/>
														</IconButton>
													</InputAdornment>
												)
											}}
											disabled={this.state.tipo === 'view'}
										/>
									)}
									startDate={this.state.fecha_inicio_pago}
									endDate={this.state.fecha_fin_pago}
									onChange={(range) => {
										this.setState({
											fecha_inicio_pago: range.startDate,
											fecha_fin_pago: range.endDate,
										});
									}}
									disabled={this.state.tipo === 'view'}
								/>
							</Grid>
							
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
								<TextField
									className={'margin-0'}
									select
									fullWidth
									margin="dense"
									required
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									SelectProps={{
										native: true,
										MenuProps: {
											className: classes.menu,
										},
									}}
									name="id_usuario"
									label="Instructor"
									value={this.state.id_usuario}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.listar_cat_instructor.map(option => (
										<option key={option.id_usuario} value={option.id_usuario}>
											{option.nombre_completo}
										</option>
									))}
								</TextField>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<FormControl class={'margin-10-T'}>
									<FormLabel component="legend">Certificado Médico</FormLabel>
									<RadioGroup
										row
										required
										aria-label="position"
										label={"Certificado Médico"}
										name="certificado_medico"
										value={this.state.certificado_medico}
										onChange={(e) => {
											this.handleChange(e);
										}}>
										
										<FormControlLabel
											labelPlacement="end"
											control={<Radio color="primary"/>}
											value={"1"}
											label={"Si"}
											disabled={this.state.tipo === 'view'}
										/>
										<FormControlLabel
											labelPlacement="end"
											control={<Radio color="primary"/>}
											value={"0"}
											label={"No"}
											disabled={this.state.tipo === 'view'}
										/>
									</RadioGroup>
								</FormControl>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<FormControl class={'margin-10-T'}>
									<FormLabel component="legend">Responsiva</FormLabel>
									<RadioGroup
										row
										required
										aria-label="position"
										label={"Responsiva"}
										name="responsiva"
										value={this.state.responsiva}
										onChange={(e) => {
											this.handleChange(e);
										}}>
										
										<FormControlLabel
											labelPlacement="end"
											control={<Radio color="primary"/>}
											value={"1"}
											label={"Si"}
											disabled={this.state.tipo === 'view'}
										/>
										<FormControlLabel
											labelPlacement="end"
											control={<Radio color="primary"/>}
											value={"0"}
											label={"No"}
											disabled={this.state.tipo === 'view'}
										/>
									</RadioGroup>
								</FormControl>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<TextField
									fullWidth
									multiline
									raw={3}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									margin="dense"
									name="notas"
									label="Notas"
									value={this.state.notas}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox type="checkbox" name='activo' checked={this.state.activo}
											          onChange={this.handleChange} value="activo" color="primary"
											          disabled={this.state.tipo === 'view'}/>
										}
										label={'Activo'}
									/>
								</FormGroup>
							
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						
						<Button onClick={() => this.modalClose()} color="primary">
							{this.state.tipo === 'view' ? 'Cerrar' : 'Cancelar'}
						</Button>
						
						{this.state.tipo !== 'view' ? (
							<Button onClick={() => this.save()} color="primary">
								{this.state.id_usuario > 0 ? 'Actualizar' : 'Agregar'}
							</Button>
						) : ''}
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalAlumnos.propTypes = {
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default IntegrationNotistack(ModalAlumnos);
